import * as React from 'react'
import { graphql, Page, PageProps } from 'gatsby'
import Seo from '../components/seo'

type DataType = {
  site: {
    siteMetadata: {
      description: string
    }
  }
}

export default function IndexPage({ data }: PageProps<DataType>) {
  return (
    <>
      <Seo />
      <main className="min-h-screen flex flex-col items-center justify-center bg-gray-50 space-y-8">
        <div>
          <h1 className="text-center text-2xl md:text-3xl lg:text-5xl font-bold text-gray-600">
            Tegridy Technologies
          </h1>
          
          <h2 className="text-center text-sm md:text-lg lg:text-2xl font-light text-gray-600 tracking-widest">
            {data.site.siteMetadata.description}
          </h2>
        </div>

        <address className="flex flex-col items-center justify-center underline not-italic text-green-800">
          <a href="https://maps.google.com/?q=Teréz krt. 20, Budapest, 1066" rel="noreferrer" target="_blank">
            1066 Budapest, Teréz krt. 20. II/1.
          </a>
          <a href="mailto:hello@tegridy.hu">
            hello@tegridy.hu
          </a>
        </address>

        <div className="flex flex-col items-center justify-center text-sm text-gray-600">
          <span className="pb-2">Tegridy Technologies Zrt.</span>
          <span><strong>Adószám:</strong> 29204777-2-42</span>
          <span><strong>Cégjegyzékszám:</strong> 01-10-141308</span>
        </div>
      </main>
    </>
  )
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`
